
import { computed, defineComponent, onBeforeMount, onMounted } from 'vue'
import { CareerPlanCreateMenuItems, CareerPlanDetailMenuItems } from '../tab-menu-items'
import { useRoute } from 'vue-router'
import { EducationRouteNames } from '@/router/route-names'
import { useEducationPlanDetail } from '@/hooks/useEducationPlanDetail'

export default defineComponent({
  setup () {
    const { params: { planId }, name: routeName } = useRoute()
    const menuItems = computed(() => {
      const opts = {
        [EducationRouteNames.CONTENT_CAREERS_DETAIL_GENERAL_INFO]: CareerPlanDetailMenuItems, // TODO revisar
        [EducationRouteNames.CONTENT_CAREERS_CREATE_ABOUT]: CareerPlanCreateMenuItems // TODO revisar
      }
      return opts[routeName as EducationRouteNames.CONTENT_CAREERS_DETAIL_GENERAL_INFO | EducationRouteNames.CONTENT_CAREERS_CREATE_ABOUT]
    })
    const { educationPlan, loadCareerPlan, loadCoursePlans, loading, coursePlans } = useEducationPlanDetail(planId as string)
    onBeforeMount(() => (loading.value = true))
    onMounted(async () => {
      try {
        const careerPlanPromise = loadCareerPlan()
        const coursePlansPromise = loadCoursePlans()
        await careerPlanPromise
        await coursePlansPromise
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })

    return {
      CareerPlanDetailMenuItems,
      educationPlan,
      coursePlans,
      menuItems,
      loading
    }
  }
})
