import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_ModuleHeader = _resolveComponent("ModuleHeader")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModuleHeader, {
          title: _ctx.educationPlan?.information.name ? 'Contenido/Carrera/' + _ctx.educationPlan.information.name : 'Creando nueva carrera'
        }, null, 8, ["title"]),
        _createVNode(_component_TabMenu, { model: _ctx.menuItems }, null, 8, ["model"]),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                educationPlan: _ctx.educationPlan,
                coursePlans: _ctx.coursePlans,
                key: _ctx.$route.fullPath
              }, null, 8, ["educationPlan", "coursePlans"]))
            ], 1024))
          ]),
          _: 1
        })
      ]))
}